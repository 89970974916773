import React from 'react';

import Layout from '../components/Layout';
import {MicroCard, ImageCard} from '../components/Card';
import pic1 from '../assets/images/icon-creativeprocess.svg';
import pic2 from '../assets/images/icon-transform.svg';

import PublicSectorImage from '../assets/images/icon-publicsector.svg';
import LowCodeNoCodeImage from '../assets/images/icon-flow.svg';
import CloudImage from '../assets/images/icon-cloudenable.svg';
import SolutionsImage from '../assets/images/icon-inventory.svg';
import TalentImage from '../assets/images/icon-talent.svg';
import ModernizeImage from '../assets/images/icon-modernize.svg';
import MicrosoftLogo from '../assets/images/ms-partner-logo-gr.png';
import D365Logo from '../assets/images/D365-logo-gr.png';
import GraphiteLogo from '../assets/images/graphitegtc-logo-gr.svg';
import IBMLogo from '../assets/images/ibm-cloud-logo-gr.png';
import GCPLogo from '../assets/images/gcp-logo-gr.png';
import AzureLogo from '../assets/images/msazure-logo-gr.png';

const herobg = '#1c2841', herofcolor='#efefef';
const TransformPage = () => (
  <Layout>
    <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
                      <h3 >Transformation</h3>
                      <h1 style={{letterSpacing:'.1em'}}>
                         Innovate & transform business solutions, excel on your core competancy.
                      </h1>
              </header>
      </div>
    
      <div className="inner row" style={{margin:'.25em auto'}}>
        <div className='col-9 col-12-medium' style={{marginTop:'10px'}}>
          
          <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
              We believe, Digital Transformation is all about rethinking business processes and 
              achieving new ways of growth by leveraging innovation.
          </p>
          
          
          <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
              Businesses across the economy are using latest technologies and advanced analytics 
              to unlock new sources of potential and achieve drastic improvements in productivity, 
              flexibility, and speed. Our digital transformation practice enables businesses to 
              engage customers, empower workforce, optimize & transform solutions.
          </p>
        
          <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
            Innovate
          </p>
          <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
            <span className="image left">
              <img src={pic1} alt="Innovate" />
            </span>
              It has always been proved that innovation drives changes and makes them a reality. 
              <br /><li>Customers would make a phone call or send an email for service.
              Pre-programmed chatbots do most of the service today by integrating and catering 
              to customers needs appropriately.</li>
              <li>Inspectors visit remote locations to measure a reading or take snapshots 
              of remotely deployed assets. Solutions enabled with Internet of Things (IoT) or 
              Drones have cut cost, time and the need of physical presence.</li>
          </p>
          <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
            Transform
          </p>
          <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
            <span className="image right">
              <img src={pic2} alt="Transform" />
            </span>
              Proven traditional business practices are put to test in the face of evolving technologies.
              Digital Marketing, Sales through various online outlets, Integrating with CRM and other 
              inhouse solutions, Customer self service through Chatbots, seemless payment 
              integration etc., have challenged the traditional way of execution and have 
              provided new ways to growth.
          </p>
          <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
              Businesses and processes could be categorized and provided with standard out of the box 
              solutions. Customized processes that differentiates the service or solution offered, 
              always requires attention to detail. Our Transformation team comprises of functional 
              experts in the traditional business models and has the capability to rethink 
              the business processes synonymous with advancing technologies.
          </p>
          
        </div>
      
        <div className='col-3 col-6-medium col-12-small' >
          <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>Explore</p>
          <MicroCard className='col-12 col-6-medium' 
                  cardTitle="Business Solutions" cardImage={SolutionsImage} 
                  cardLink='/business-solutions-on-the-cloud'/>
          <MicroCard className='col-12 col-6-medium' 
                  cardTitle="Public Sector Services" cardImage={PublicSectorImage} 
                  cardLink='/public-sector-services'/>
          <MicroCard className='col-12 col-6-medium' 
                  cardTitle="Talent Services" cardImage={TalentImage} 
                  cardLink='/talent-services'/>
          <MicroCard className='col-12 col-6-medium' 
                  cardTitle="Low Code/No Code" cardImage={LowCodeNoCodeImage} 
                  cardLink='/lowcode-nocode'/>
          <MicroCard className='col-12 col-6-medium' 
                  cardTitle="Modernize" cardImage={ModernizeImage} 
                  cardLink='/modernize-solutions'/>
          <MicroCard className='col-12 col-6-medium' 
                  cardTitle="Enable the Cloud" cardImage={CloudImage} 
                  cardLink='/enable-the-cloud'/>
          
            
            
          <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>Technology Partners</p>
          <ImageCard className='col-12 col-6-medium' cardImage={GraphiteLogo} height='20px'/>
          <ImageCard className='col-12 col-6-medium' cardImage={MicrosoftLogo} />
          <ImageCard className='col-12 col-6-medium' cardImage={D365Logo} height='35px'/>
          <ImageCard className='col-12 col-6-medium' cardImage={AzureLogo} height='20px'/>
          <ImageCard className='col-12 col-6-medium' cardImage={GCPLogo}  height='20px'/>
          <ImageCard className='col-12 col-6-medium' cardImage={IBMLogo} height='25px'/>
              
                
                
        
        </div>
      </div>
    </div>
  </Layout>
);

export default TransformPage;
